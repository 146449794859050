<template>
  <div class="container">
    <div class="row faqs mx-auto mb-5 pb-5">
      <div class="col-md-12">
        <h1 class="text-uppercase text-center mt-5 mb-5">
          Frequently Asked Questions
        </h1>
        <div class="accordion" id="faqAccordion">
          <div class="card" v-for="(faq, index) in faqs" :key="index">
            <div class="card-header" :id="'heading' + index">
              <h2 class="accordion-header">
                <button :class="{
                  'arrow-open': activeIndex === index,
                  'arrow-closed': activeIndex !== index,
                }" class="accordion-button" type="button" @click="toggle(index)"
                  :aria-expanded="activeIndex === index ? 'true' : 'false'" :aria-controls="'collapse' + index">
                  <span v-html="faq.question"></span>
                  <span></span>
                </button>
              </h2>
            </div>
            <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ show: activeIndex === index }"
              :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
              <div class="card-body" v-html="faq.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question:
            'When will I receive my book?',
          answer:
            'After your submission is validated, please allow 3 - 5 weeks for delivery. Your book will be mailed to the address that you provided. ',
        },
        {
          question: 'How many books can I qualify for?',
          answer:
            'There is a limit of five (5) books per household.',
        },
        {
          question: 'Which Kellogg’s<sup>®</sup> products do I need to purchase to qualify for a book? ',
          answer:
            'The offer is only open on specially-marked boxes of Kellogg’s<sup>®</sup> cereals. Participating Kellogg’s<sup>®</sup> cereals can be found <a href="https://snipp.us/!ctbeU" target="_blank">here</a>. ',
        },
        {
          question:
            'Do I need to purchase all products in one transaction? ',
          answer:
            'Yes, you must purchase two qualifying products in a single transaction. Purchases must be made on or before March&nbsp;31,&nbsp;2025. ',
        },
        {
          question: 'How many times can I submit the same valid proof of purchase? ',
          answer:
            'You can submit a valid proof of purchase only once. ',
        },
        {
          question: 'Why does it say to upload each proof of purchase separately? ',
          answer:
            'If you upload more than one proof of purchase record in a single submission, your submission will be rejected and you will be directed to submit each document as part of a new, separate submission. ',
        },
        {
          question: 'How do I know if my submission/request for a book has been received? ',
          answer: 'Each time you upload a proof of purchase record, you will receive an email advising we have received your submission. If you don’t see the email in your inbox please check your spam or junk folders or any other folder that your email provider may have automatically set up within your account. ',
        },
        {
          question: 'What happens after I complete my submission? ',
          answer:
            'Your submission will be reviewed and will either be approved or declined within 72 hours. Keep your receipt or proof of purchase record until you receive your book through the mail. Please allow 3 - 5 weeks for delivery. ',
        },
        {
          question: 'When is the last day to submit a proof of purchase? ',
          answer: 'The last day to submit a proof of purchase is April&nbsp;30,&nbsp;2025. Submissions will be reviewed and approved (or rejected) if they are submitted by 11:59:59&nbsp;PM&nbsp;(EST). ',
        },
        {
          question:
            'How do I submit a proof of purchase from an online retailer? ',
          answer: 'Order records will not be accepted. You must upload a clear photo or PDF file of your order shipping, delivery or pick-up confirmation record. Be sure your document includes the following information: <br />Retailer name and website&nbsp;<br />Date your order was placed&nbsp;<br />Date your order was delivered or picked-up&nbsp;<br />Description and quantity of participating Kellogg&rsquo;s<sup>&reg;</sup> cereal product(s) purchased&nbsp;<br />Total dollar value of your full transaction&nbsp;<br />Shipping address, if applicable',
        },
        {
          question: 'Who can I contact if I have questions? ',
          answer:
            'Please contact us via  <a href="https://www.wkkellogg.ca/en-ca/contact-us.html" target="_blank">https://www.wkkellogg.ca/en-ca/contact-us.html</a> and reference the same email address you used to register for the promotion. Include your name and any relevant submission confirmation numbers, if applicable. ',
        },
        {
          question:
            'Can I qualify for multiple books within a single purchase? ',
          answer: 'Yes, every two (2) qualifying products found on an individually submitted proof of purchase record will earn you one (1) book, up to a maximum of five (5) book per person and per household. For example, if you submit a proof of purchase that has six (6) participating products, you will receive three (3) books.  ',
        },
        {
          question: 'Can I submit my receipt by any means other than the website? ',
          answer: 'If, for whatever reason, you are unable to register at the promotion website or upload your proof of purchase, you can send your proof of purchase record to the following address: <br><br>WK Kellogg Canada Feeding Reading Offer <br>P.O. Box 250-KG003 <br>Pickering, ON <br>L1V 2R4 <br><br>Your mailed-in submission must also include the following information: <br><br>Full name <br>Full mailing address including postal code <br>Valid Email address <br>Original proof of purchase record (retain a copy for your own records)<br><br>Include your top 3 book choices:<br>1<sup>st</sup> Choice:<br>2<sup>nd</sup> Choice:<br>3<sup>rd</sup> Choice:',
        },
      ],
      activeIndex: null,
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
#faqAccordion>.card {
  margin-bottom: 15px;
  border-radius: 0px;
  border: 2px solid #ca1246;
  background: transparent;
}

#faqAccordion>.card>.card-header {
  padding: 0px;
  background: transparent;
  border-color: #ca1246;
}

#faqAccordion .accordion-button {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 600;
  padding: 15px 30px 15px 15px;
  box-shadow: none;
  background: transparent;
}

#faqAccordion .accordion-button:focus,
#faqAccordion .accordion-button.focus,
#faqAccordion .accordion-button:hover {
  text-decoration: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
}

.accordion-button {
  transition: background-color 0.3s ease;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  font-family: 'FontAwesome';
  background-image: none;
}

.accordion-button.arrow-open::after {
  content: '\f078';
}

.accordion-button.arrow-closed::after {
  content: '\f077';
}

@media screen and (max-width: 767px) {
  #faqAccordion .accordion-button {
    font-size: 1.2rem;
  }
}
</style>
